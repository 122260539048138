<template>
  <div>

      <b-overlay
          :show="vacantShiftsLoading"
          rounded="sm"
      >
<!--        <b-row class="mb-1">-->
<!--          <b-col cols="12">-->
<!--            <b-alert-->
<!--                show-->
<!--                variant="primary"-->
<!--            >-->
<!--              <div class="alert-body">-->
<!--                <feather-icon-->
<!--                    class="mr-25"-->
<!--                    icon="AlertCircleIcon"-->
<!--                />-->
<!--                <span class="ml-25">All listed shifts are applicable to your role only.</span>-->
<!--&lt;!&ndash;                <span class="ml-25">All listed shifts are applicable to your role only: Occupational Therapist.</span>&ndash;&gt;-->
<!--              </div>-->
<!--            </b-alert>-->
<!--          </b-col>-->
<!--        </b-row>-->
        <b-row class="mb-1">
          <b-col cols="4">
            <b-form-group>
              <flat-pickr
                  v-model="filters['start']"
                  :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d',dateFormat: 'Y-m-d',locale: {
                        firstDayOfWeek: 1
                      }}"
                  class="form-control"
                  placeholder="Select Date"
              />
            </b-form-group>
          </b-col>

          <b-col cols="8">
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-overlay
              :show="tableLoading"
              rounded="sm"
          >
            <b-table
                :current-page="currentPage"
                :per-page="pagination.perPage"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="getVacantShiftApplications"
                :sort-by.sync="sortBy"
                :eventDoubleClick="false"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                hover
                ref="tableApplyPcn"
                class="mobile_table_css"
                responsive
            >
<!--              <template #cell(show_details)="row">-->
<!--                <b-form-checkbox-->
<!--                    v-model="row.detailsShowing"-->
<!--                    plain-->
<!--                    class="vs-checkbox-con"-->
<!--                    @change="row.toggleDetails"-->
<!--                >-->
<!--          <span class="vs-checkbox">-->
<!--            <span class="vs-checkbox&#45;&#45;check">-->
<!--              <i class="vs-icon feather icon-check" />-->
<!--            </span>-->
<!--          </span>-->
<!--                  <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>-->
<!--                </b-form-checkbox>-->
<!--              </template>-->
<!--              <template #row-details="row">-->
<!--                <b-card>-->
<!--                  <b-row class="mb-2">-->
<!--                  <b-col-->
<!--                      md="4"-->
<!--                      class="mb-1"-->
<!--                  >-->
<!--                    <h2 class="mb-1">Practice manager</h2>-->
<!--                    <p><strong>Name : </strong>{{ row.item.created_by.first_name}} {{row.item.created_by.last_name}}</p>-->
<!--                    <p><strong>Email : </strong>{{ row.item.created_by.email }}</p>-->
<!--                    <p><strong>Phone : </strong>{{ row.item.created_by.phone_number || 'N/A' }}</p>-->
<!--                  </b-col>-->
<!--                    <b-col-->
<!--                        md="4"-->
<!--                        class="mb-1"-->
<!--                    >-->
<!--                      <h2 class="mb-1">Rate</h2>-->
<!--                      <p><strong>Per hour : </strong>250</p>-->
<!--                      <p><strong>Total Rate : </strong>250</p>-->
<!--&lt;!&ndash;                      <p><strong>Phone : </strong>{{ row.item.created_by.phone_number || 'N/A' }}</p>&ndash;&gt;-->
<!--                    </b-col>-->
<!--                </b-row>-->
<!--                </b-card></template>-->

              <template #cell(action)="data">
                <!-- <b-button variant="primary" :disabled="true" v-if="data.item.applications == 'Yes'" >Applied</b-button> -->
                <b-badge  v-if="data.item.applications == 'Yes'"
                    :variant="'light-success'"
                    class="text-capitalize"
                >
                  appled
                </b-badge>
                <b-button variant="primary" v-else @click="applyVacantShifts(data.item.id)">Apply</b-button>
              </template>

            </b-table>
            </b-overlay>
          </b-col>
          <b-col cols="12" v-if="noDataTable === 0" class="text-center">
            <span >No data for preview</span>
          </b-col>
          <!-- Pagination -->

          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
            <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>

          </b-col>

          <!-- Pagination -->

          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

            <b-pagination
                v-model="currentPage"
                :current-page="currentPage"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                class="mb-0 mt-1 mt-sm-0 "
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item">

              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>

            </b-pagination>

          </b-col>
        </b-row>
      </b-overlay>



  </div>





</template>

<script>

import {
  BAlert,
  BCard,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox, BFormDatepicker, BFormInvalidFeedback, BFormTextarea,
  BInputGroupPrepend, BSidebar,
  BTab,
  BTabs,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import quickInfoTContentmplate from './eventView/content'
import quickInfoHeaderTemplate from './eventView/header'
import quickInfoFooterTemplate from './eventView/footer'
import weekTemplate from '@/views/my-shifts/eventsTemplates/weekTemplate'
import monthTemplate from '@/views/my-shifts/eventsTemplates/monthTemplate'

import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,

  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import settings from '@/apis/modules/settings'
import shift from '@/apis/modules/shift'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import MomentMixin from '@/mixins/MomentMixin'
import vSelect from 'vue-select'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Vue from "vue";
import { extend } from '@syncfusion/ej2-base';
import {
  SchedulePlugin,
  Week,
  Month,
  DragAndDrop,
  Agenda,
  TimelineViews,
  TimelineMonth, Resize, Print
} from '@syncfusion/ej2-vue-schedule'
import { DatePickerPlugin, ChangeEventArgs } from '@syncfusion/ej2-vue-calendars';
import leave from '@/apis/modules/leave'
Vue.use(SchedulePlugin);
Vue.use(DatePickerPlugin);
import flatPickr from 'vue-flatpickr-component'
import practice from '@/apis/modules/practice'
import Filter from '@/mixins/FilterMixin'
import ProfileAPI from '@/apis/modules/profile'
export default {
  components: {
    weekTemplate,
    flatPickr,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
    BInputGroupPrepend,
    BCard,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    VBTooltip,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],
  data() {
    return {
      swapRequestListLength: 0,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filters: {},
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'date',
          label: 'Date',
        },
        {
          key: 'programme',
          label: 'PROGRAMME',
        },
        {
          key: 'subject',
          label: 'SERVICE',
        },
        {
          key: 'time',
          label: 'Time',
        },
        {
          key: 'action',
          label: ' ',
        },


        // {
        //   key: 'action',
        //   label: '',
        // },

      ],

      noDataTable: '',
      showSwapRequestModel: false,
      tableLoading: false,
      selectedDateForFilter: '',
      vacantShiftsLoading: false,
      eventSettings: {dataSource: ''},
      selectedDate: new Date(),
      items: [],
      currentView: 'Week',
      currentUserId: '',
      timeScale: {
        enable: true,
        interval: 60,
        slotCount: 2

      },
    }
  },

  methods: {
    async applyVacantShifts (id) {
      try {
        this.tableLoading = true
        await shift.applyPCNVacantShift(id)
        this.showSuccessMessage('Shift Applied Successfully')
        this.$refs.tableApplyPcn.refresh()
        this.tableLoading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false

      }
    },
    async getCurrentUser () {
      const user = (await ProfileAPI.getCurrentUser()).data.data
      this.currentUserId = user.id
    },
    async getVacantShiftApplications () {
      this.tableLoading = true
      try {
        const Response = await shift.getPCNApplicationListVacantShifts(this.filterQuery, this.currentPage, 15)
        this.noDataTable = Response.data.data.length
        let dataArray = Response.data.data.map((x) => ({
          id: x.id,
          date: this.momentFormat(x.start, 'DD-MMM-YY'),
          programme: x.pcn_programme?x.pcn_programme.name: 'N/A',
          subject: x.service === null ? 'N/A' : x.service.name,
          rate: x.salary_staff_rate ? x.salary_staff_rate : 0,
          time: `${this.momentFormat(x.start, 'HH:mm')}` + ' ' + `${this.momentFormat(x.end, 'HH:mm')}`,
          applications: `${this.vacuntShiftAppliedCheck(x.applications, this.currentUserId)}`,
          created_by: x.created_by
        }))
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.$emit('clicked', Response)
        this.tableLoading = false
        return dataArray


      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    filterQueryUpdate() {
      this.$refs.tableApplyPcn.refresh()
    },

  },
  mounted(){
    this.getCurrentUser()
  }


}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss'; /*print style*/
@import '@core/scss/vue/libs/vue-flatpicker.scss';


</style>
<style>

div#dropdown-1 .btn-outline-danger {
  border: none !important;
}

[dir] .btn.btn-icon {
  padding: 0.715rem 0.736rem;
  background-color: white;
}

[dir] .e-quick-popup-wrapper .e-event-popup .e-popup-header {
  background-color: white;
}


[dir=ltr] .vs--single .vs__dropdown-toggle {
  background-color: white;

}
.swapButton{
  background-color: white;
}


</style>
